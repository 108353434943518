<template>
  <div class="operationTable">
    <comps-table  selectionType ='selection' :expand="expand" :tableData="tableData" :tableHeader="tableHeader" :pageSize='pageSize' :total='total' @current-events="getList" @save-current="savePage" v-loading='isLoading'
      element-loading-text="拼命加载中"
      element-loading-customClass='el-icon-loading'
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.7)"
      v-if="initStatus"
      @size-events="changeSize"
      ref="multipleTable"
      @multipleSelection="selection">
    </comps-table>
    <nodata :message="'暂无相关日志'" h="400" v-else />
  </div>
</template>

<script>
  import compsTable from "@/components/table/table";
  export default {
    props: ['value','invoiceTime','reimburseTime','invoiceCode','invoiceNo','reimburseUser','userId','startQuery'],
    name: "operationTable",
    components: {
      compsTable,
    },
    data() {
      return {
        tableData: [], // 请求到的表格数据
        tableHeader: [
          // 表头信息
          {
            prop: "invoiceCode",
            center: 'left',
            label: "发票代码",
          },
          {
            prop: "invoiceNo",
            label: "发票号码",
          },
          {
            prop: "invoiceAmount",
            label: "开票金额",
          },
          {
            prop: "invoiceTime",
            label: "开票日期",
            formatter: (row) => {
              return row.invoiceTime.slice(0,10);
            },
          },
          {
            prop: "reimburseTime",
            label: "报销日期",
            formatter: (row) => {
              return row.reimburseTime.slice(0,10);
            },
          }, {
            prop: "reimburseName",
            label: "报销人",
          }, {
            prop: "reimburseAmount",
            label: "报销金额",
          }
        ],
        multipleSelection: [],
        exportForm:{},
        //分页查询
        page: 1,
        data: [],
        total: 0,
        pageSize: 20,
        current: '',
        isLoading: false,
        initStatus : false,
        expand:true
      };
    },
    methods: {
      changeSize(size) {
        this.pageSize = size
        this.getList()
      },
      // 所有实例列表
      savePage(val) {
        this.current = val
      },
      doDownloadLog(item){
        this.rowData = item;
      },
      selection(val){
        this.$emit('selectionList',val)
      },
      getList(val) {
        this.isLoading = true
        var queryForm = {
          companyId:  this.getToken('companyId'), //公司Id
          page: val ? val : this.page,
          rows: this.pageSize,
          invoiceTimeStart : this.invoiceTime[0] ? this.invoiceTime[0]: null,
          invoiceTimeEnd : this.invoiceTime[1] ? this.invoiceTime[1] : null,
          reimburseTimeStart : this.reimburseTime[0] ? this.reimburseTime[0]: null,
          reimburseTimeEnd : this.reimburseTime[1] ? this.reimburseTime[1] : null,
          invoiceCode:this.invoiceCode,
          invoiceNo:this.invoiceNo,
          reimburseUser:this.reimburseUser
        }
        this.$store.dispatch("dupCheckfindList", {
         ...queryForm
        }).then(res => {
          this.isLoading = false
          if (res.success) {
              this.exportForm = queryForm
              this.initStatus = true;
              this.total = res.totalCount;
              this.tableData = res.data;
          } else {
            this.initStatus = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },

      sortEvents(type) {
        console.log(type);
      },
    },
    mounted() {
     /* this.getList();*/
    },
    watch:{
      startQuery(val) {
        this.getList();
      }
    }
  };
</script>

<style scoped>
  .operationTable{
    height: 100%;
    background: #fff;
  }
</style>
